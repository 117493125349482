
.sudoku-gamesection{
  height: auto;
  width: 100vw;
  /* overflow: scroll;
  background-image: url('../../Assets/background.jpg');
  background-size:cover; */
  overflow-x: hidden;
}

#sudoku-dropdown-basic{
  display: block;
  margin: auto;
  border: none;
  background-color: #7F5AF0;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
}

.sudoku-dropdown-options{
  background-color: white;
  color:#7F5AF0;
  padding: 10px;
}

.sudoku-dropdown-options:hover{
  background-color: #7F5AF0;
  color:white;
  transform: scale(1.05,1.05);
  border-radius: 5px;
}

.sudoku-dropdown-options:disabled{
  opacity: 0.5;
}

#sudoku-result{
  box-sizing: border-box;
  width: 100%;
  height: 50%;
}


.sudoku-land-page{
  height: 100vh;
  width: 100vw;
  display: block;
  text-align: center;
  padding: 10vh 20vw;
}

#sudoku-StartButton{
  background-color: #2CB67D;
  padding: 10px 20px;
  border-radius: 4px;
  border: transparent;
  color: #FFFFFF;
  font-family: Iceland;
}

#sudoku-subland{
  padding: 10vh 10vw;
}

#sudoku-headingland{
  font-family: Saira Stencil One;
  font-style: normal;
  font-weight: normal;
  font-size: 78px;
  color: #94A1B2;
}

#sudoku-gameland{
  display: none;
  width: 100vw;
  height: 100vh;
}


.sudoku-gamebttn{
    border: none;
    padding: 10px 20px !important;
    margin: 5vh 5px;
  }
  
  
.sudoku-gamebttn:hover{
    transform: scale(1.1,1.1);
}
  
.sudoku-square {
    text-align: center;
    border-radius: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
    font-size: 2rem;
}
  
.sudoku-square:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
  
  .sudoku-grid {
    display: grid;
    width: 80%;
    height: 100%;
    margin: auto;
    grid-template: repeat(9, 1fr) / repeat(9, 1fr);
  }
  
  .sudoku-grid *:nth-child(9n + 6) , .sudoku-grid *:nth-child(9n + 3) {
    border-right: 5px solid #94A1B2;
  }
  
  
  .sudoku-grid *:nth-child(n + 19):nth-child(-n + 27), *:nth-child(n + 46):nth-child(-n + 54) {
    border-bottom: 5px solid #94A1B2;
  }

